<template>
  <!--begin::Pagination -->
  <div
    class="d-flex justify-content-between align-items-center flex-wrap"
  >
    <div class="d-flex flex-wrap mr-3">
      <button
        :disabled="isFirstButtonDisabled"
        @click="firstPage"
        class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
      >
        <i class="ki ki-bold-double-arrow-back icon-xs"></i>
      </button>
      <button
        :disabled="isPreviousButtonDisabled"
        @click="previousPage"
        class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
      >
        <i class="ki ki-bold-arrow-back icon-xs"></i>
      </button>

      <PaginationTrigger
        v-for="paginationTrigger in paginationTriggers"
        :class="{
          active: paginationTrigger === currentPage
        }"
        :key="paginationTrigger"
        :pageNumber="paginationTrigger"
        @loadPage="onLoadPage"
      ></PaginationTrigger>

      <button
        :disabled="isNextButtonDisabled"
        @click="nextPage"
        class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
      >
        <i class="ki ki-bold-arrow-next icon-xs"></i>
      </button>
      <button
        :disabled="isLastButtonDisabled"
        @click="lastPage"
        class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
      >
        <i class="ki ki-bold-double-arrow-next icon-xs"></i>
      </button>
    </div>

    <div class="d-flex align-items-center">
      <span class="text-muted"
        >Displaying {{ perPage }} of {{ itemCount }} records</span
      >
    </div>
  </div>
  <!--end::Pagination -->
</template>

<script>
import PaginationTrigger from "./PaginationTrigger";

export default {
  name: "Pagination",
  components: {
    PaginationTrigger
  },
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    itemCount: {
      type: Number
    },
    perPage: {
      type: Number
    },
    visiblePagesCount: {
      type: Number,
      default: 5
    }
  },
  computed: {
    isPreviousButtonDisabled() {
      return this.currentPage === 1;
    },
    isNextButtonDisabled() {
      return this.currentPage === this.pageCount;
    },
    isFirstButtonDisabled() {
      return this.currentPage === 1;
    },
    isLastButtonDisabled() {
      return this.currentPage === this.pageCount;
    },
    paginationTriggers() {
      const currentPage = this.currentPage;
      const pageCount = this.pageCount;
      const visiblePagesCount = this.visiblePagesCount;
      const visiblePagesThreshold = (visiblePagesCount - 1) / 2;
      const pagintationTriggersArray = Array(this.visiblePagesCount - 1).fill(
        0
      );

      if (currentPage <= visiblePagesThreshold + 1) {
        pagintationTriggersArray[0] = 1;
        const pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return pagintationTriggersArray[0] + index;
          }
        );
        return pagintationTriggers;
      }

      if (currentPage >= pageCount - visiblePagesThreshold + 1) {
        const pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return pageCount - index;
          }
        );
        pagintationTriggers.reverse();
        return pagintationTriggers;
      }

      pagintationTriggersArray[0] = currentPage - visiblePagesThreshold + 1;
      const pagintationTriggers = pagintationTriggersArray.map(
        (paginationTrigger, index) => {
          return pagintationTriggersArray[0] + index;
        }
      );
      return pagintationTriggers;
    }
  },
  methods: {
    firstPage() {
      this.$emit("firstPage");
    },
    nextPage() {
      this.$emit("nextPage");
    },
    previousPage() {
      this.$emit("previousPage");
    },
    lastPage() {
      this.$emit("lastPage");
    },
    onLoadPage(value) {
      this.$emit("loadPage", value);
    }
  }
};
</script>

<style scoped></style>
