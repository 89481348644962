<template>
  <button
    @click="onClick"
    class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
  >
    {{ pageNumber }}
  </button>
</template>

<script>
export default {
  name: "PaginationTrigger",
  props: {
    pageNumber: {
      type: Number,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$emit("loadPage", this.pageNumber);
    }
  }
};
</script>

<style scoped></style>
