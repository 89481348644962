<template>

  <div v-if="process.publiclyVisible != false"  class="row mb-8">

    <div class="col-12 col-sm-7 col-lg-8">
      <div class="card card-custom card-stretch shadow-sm ribbon ribbon-clip ribbon-left align-items-center">
        <div class="ribbon-target p-3" style="top: 12px;">
          <span
            class="ribbon-inner"
            v-bind:class="{
              'bg-primary': processStatus.label == $t('PARTIALSPROCESSI.INCORSO'),
              'bg-warning': processStatus.label == $t('PARTIALSPROCESSI.INATTIVAZIONE'),
              'bg-danger': processStatus.label == $t('PARTIALSPROCESSI.CONCLUSO')
            }"
          ></span><span class=" text-uppercase font-weight-bolder">{{ processStatus.label }}</span>
        </div>
        <div class="row w-100 align-items-center">
          <div class="d-sm-none d-md-block col-md-3 col-lg-4 col-xl-3">
            <img
              :src="
                process.logo
                  ? process.logo.uri
                  : 'assets/media/bipart/banner.png'
              "
              class="pt-5 pb-5 pr-0 pl-5 w-100"
              alt=""
            />
          </div>
          <div class="col-sm-10 col-md-9 col-lg-8 col-xl-9 pt-sm-15 pt-md-0">
            <div class="d-flex justify-content-between p-5 pb-0">
              <div class="d-flex">
<!--                <div class="d-flex mr-2">-->
<!--                  <span class="label label-inline label-xl font-weight-boldest label-rounded py-5"-->
<!--                    v-bind:class="{ 'label-light-primary': process.publiclyVisible, 'label-light-danger' : !process.publiclyVisible }"-->
<!--                  >-->
<!--                  <i class="fas fa-lock-open text-primary" v-if="process.publiclyVisible != false"></i>-->
<!--                  <i class="fas fa-lock text-danger" v-else></i>-->
<!--                  </span>-->
<!--                </div>-->
                <div class="d-flex align-items-center">
                    <span class="" v-if="process.startDate"> {{$t('GENERALLABEL.DAL')  | capitalize}} <b class="font-weight-bolder h5"> {{ process.startDate | moment("DD/MM/Y") }}</b> </span>
                    <span class="ml-4" v-if="process.endDate"> {{$t('GENERALLABEL.AL')}} <b  class="font-weight-bolder h5">{{ process.endDate | moment("DD/MM/Y") }}</b></span>
                </div>
              </div>
            </div>
            <div class="p-0 px-5">
<!--              se il processo ha più di una fase, porta a processDetail; se ha una sola fase: se la fase ha un più moduli, porta a processPhase, altrimenti a idealist-->
              <router-link
                  :to="isManyPhases ? { name: 'processDetail', params: { id: process.id } } : phases[0].countProcess2Modules > 1 ? { name : 'processPhase', params: { phase_id: phases[0].id, process_id: process.id } } : { name: 'processModuleIdeaList', params: { id: phases[0].singleProcess2ModuleId, process_id: process.id, phase_id: phases[0].id } }"
                  tag="button"
                  class="w-100 text-left"
              >
                <h3 v-if="process.localizations[0]" class="text-primary text-hover-primary font-size-h2 font-weight-bolder mb-3">
                    {{ process.localizations[0].title | truncate(40, '...') }}
                </h3>
                <div v-if="process.localizations[0]" class="d-none d-sm-block font-weight-normal font-size-h6 max-h100px" maxlength="100">
                  {{ process.localizations[0].description  | truncate(150, '...')}}
                </div>
              </router-link>
            </div>

            <div class="progress-container mt-5 ml-5" v-if="phases && isManyPhases">
              <div class="progress-phase bg-primary"></div>
              <div
                  v-for="item in phases"
                  :key="item.id"
                  style="z-index: +1"
              >
                <a
                  v-if="item.phaseStatus.id==1"
                  href="#"
                  @click="alertNotActive"
                >
                  <div class="ps-circle" :class="[(activePhase && item.id == activePhase.id) ? 'active' : 'btn-primary']">
                    {{ item.seqStrict }}
                  </div>
                </a>
                <!-- se c'è un solo modulo (singleProcess2ModuleId != null), mi manda alla homepage del modulo (idealist) -->
                <router-link v-else-if="item.singleProcess2ModuleId != null" :to="{ name: 'processModuleIdeaList', query: { forced_process: true }, params: { id: item.singleProcess2ModuleId, process_id: process.id, phase_id: item.id } }">
                  <div class="ps-circle" :class="[(activePhase && item.id == activePhase.id) ? 'active' : 'btn-primary']">
                    {{ item.seqStrict }}
                  </div>
                </router-link>
                <!-- alrimenti vai alla homepage di fase (processPhase) -->
                <router-link
                  v-else
                  :to="{ name: 'processPhase', params: { phase_id: item.id, process_id: process.id } }"
                >
                  <div class="ps-circle" :class="[(activePhase && item.id == activePhase.id) ? 'active' : 'btn-primary']">
                    {{ item.seqStrict }}
                  </div>
                </router-link>
              </div>
            </div>
            <div class="pb-5" v-else></div>
            </div>
        </div>

        <!-- <div
            class="d-flex mb-1 align-items-center"
          >
            <span class="d-block font-weight-bold mr-5">Progress</span>
            <div class="d-flex flex-row-fluid align-items-center">
              <div class="progress progress-xs mt-2 mb-2 w-100">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  :style="'width: ' + percentage + '%;'"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span class="ml-3 font-weight-bolder">{{ percentage }}%</span>
            </div>
          </div> -->
        <!--end::Body-->
        <!--begin::Footer
        <div class="card-footer d-flex align-items-right justify-content-between">
          <div class="d-flex">
            <div class="d-flex align-items-center mr-7">
              <a href="#" class="font-weight-bold text-dark-75 text-hover-primary mb-1"> <span class="font-size-h3 text-primary">{{ process.users.length }}</span> utenti </a>
            </div>
            <div class="d-flex align-items-center mr-7">
              <a href="#" class="font-weight-bold text-dark-75 text-hover-primary mb-1"> <span class="font-size-h3 text-primary">5</span> contenuti </a>
            </div>
            <div class="d-flex align-items-center mr-7">
              <a href="#" class="font-weight-bold text-dark-75 text-hover-primary mb-1"> <span class="font-size-h3 text-primary">0</span> interazioni </a>
            </div>
          </div>
          <div class="d-flex flex-column text-right">
            <div class="card-toolbar">
              <div
                v-if="processStatus.label === 'Concluso' || index == 0">
              <router-link
                :to="{ name: 'processDetail', params: { id: process.id } }"
              >
                <div class="btn btn-primary text-white  btn-lg">
                  <span
                    class="font-weight-bolder font-size-base text-uppercase"
                    >visualizza</span
                  >
                </div>
              </router-link>
              </div>
              <div
                v-else-if="
                  (!process.publiclyVisible) || index == 1
                "
              >
                <router-link
                  :to="{ name: 'processDetail', params: { id: process.id } }"
                >
                  <div class="btn btn-danger text-white  btn-lg">
                    <span
                      class="font-weight-bolder font-size-base text-uppercase"
                      >ask to join</span
                    >
                  </div>
                </router-link>
              </div>
              <div
                v-else-if="
                  (process.publiclyVisible) || index == 2
                "
                class="card-toolbar"
              >
                <router-link
                  :to="{ name: 'processDetail', params: { id: process.id } }"
                >
                  <div class="btn btn-warning  text-white  btn-lg">
                    <span
                      class="font-weight-bolder font-size-base text-uppercase"
                      >Partecipa</span
                    >
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        end::Footer-->
      </div>
    </div>
    <div class="col-12 col-sm-5 col-lg-4">
      <div class="card card-custom card-stretch shadow-sm bg-primary align-items-between">
        <div class="card-header text-white bg-primary py-5 border-0">
          <h3 class="mt-3">{{ (activePhase) ? activePhase.localizations[0].title : '---'}}</h3>
          <p v-if="activePhase">
            <span v-if="activePhase.phaseStatus.id == 1">
              {{activePhase.localizations[0].description  | truncate(150, '...')}}
            </span>
            <span v-else-if="activePhase.phaseStatus.id == 2">
              {{activePhase.localizations[0].openDescription  | truncate(150, '...')}}
            </span>
            <span v-else-if="activePhase.phaseStatus.id == 3">
              {{activePhase.localizations[0].closedDescription  | truncate(150, '...')}}
            </span>
          </p>
        </div>
        <div class="card-body text-white  pt-0 pb-6 border-0 align-items-end mt-2 mb-3 d-flex">
          <div class="d-flex flex-fill align-items-center justify-content-end">
            <span class="bullet bullet-bar bg-white align-self-stretch" v-if="isManyPhases"></span>
            <div class="d-flex flex-column flex-grow-1 ml-3" v-if="isManyPhases">
              <div v-if="activePhase" class="font-weight-bold font-size-lg mb-1 h4">{{$t('GENERALLABEL.FASE') | uppercase }} {{ activePhase.seqStrict}}</div>
              <span
                  v-if="activePhase && activePhase.closeDate"
                  class="text-white font-weight-bold">
                {{$t('GENERALLABEL.TERMINAIL')}} {{ activePhase.closeDate | moment("DD/MM/Y")  }}</span>
            </div>
            <div v-if="activePhase" class="card-toolbar">

<!--              nuovo-->
              <a
                  v-if="activePhase.phaseStatus.id==1"
                  href="#"
                  @click="alertNotActive"
              >
                <button class="btn btn-secondary text-uppercase font-weight-bolder py-2 px-6 rounded-0">{{$t("PARTIALSPROCESSI.VISUALIZZA")}}</button>
              </a>
<!--              /nuovo-->
              <!-- se c'è un solo modulo, vai alla homepage del modulo -->
               <router-link v-else-if="activePhase.singleProcess2ModuleId != null" :to="{ name: 'processModuleIdeaList', query: { forced_process: true }, params: { id: activePhase.singleProcess2ModuleId, process_id: process.id, phase_id: activePhase.id } }"
                tag="button"
                class="w-100 text-left"
              >
              <button class="btn btn-secondary text-uppercase font-weight-bolder py-2 px-6 rounded-0">{{$t("PARTIALSPROCESSI.VISUALIZZA")}}</button>
               </router-link>
              <!-- altrimenti, vai alla homepage di fase -->
              <router-link v-else
                :to="{ name: 'processPhase', params: { phase_id: activePhase.id, process_id: process.id } }"
                tag="button"
                class="w-100 text-left"
              >
              <button class="btn btn-secondary text-uppercase font-weight-bolder py-2 px-6 rounded-0">{{$t("PARTIALSPROCESSI.VISUALIZZA")}}</button>
               </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div v-else>
     <a
      href="#"
      @click.prevent="alertInfo"
      id="pop-info"
    >
    <div class="card gutter-b card-stretch shadow-sm ribbon ribbon-clip ribbon-right">
      <div class="ribbon-target p-3" style="top: 12px;">
        <span
            class="ribbon-inner"
            v-bind:class="{
              'bg-primary': processStatus.label == $t('PARTIALSPROCESSI.INCORSO'),
              'bg-warning': processStatus.label == $t('PARTIALSPROCESSI.INATTIVAZIONE'),
              'bg-danger': processStatus.label == $t('PARTIALSPROCESSI.CONCLUSO')
            }"
        ></span><span class=" text-uppercase font-weight-bolder">{{ processStatus.label }}</span>
      </div>
        <div class="row w-100">
          <div class="d-none d-md-block col-lg-3 col-xl-2">

              <img
                :src="
                  process.logo
                    ? process.logo.uri
                    : 'assets/media/bipart/banner.png'
                "
                class="pt-5 pb-5 pr-0 pl-5 w-100"
                alt=""
              />
          </div>
          <div class="col-12 col-md-12 col-lg-9 col-xl-10">
            <div class="d-flex justify-content-between py-5">
              <div class="d-flex">
                <div class="d-flex mr-2">
                  <span class="label label-inline label-xl font-weight-boldest label-rounded py-5"
                    v-bind:class="{ 'label-light-primary': process.publiclyVisible, 'label-light-danger' : !process.publiclyVisible }"
                  >
                  <i class="fas fa-lock-open text-primary" v-if="process.publiclyVisible != false"></i>
                  <i class="fas fa-lock text-danger" v-else></i>
                  </span>
                </div>
                <div class="d-flex align-items-center">
                  <div class="card-toolbar mt-2">
                    <span v-if="process.startDate"
                      class="font-weight-bolder"
                    >
                      {{ process.startDate | moment("DD/MM/Y") }}</span
                    >
                    <span v-if="process.endDate"> | </span>
                    <span  v-if="process.endDate"
                      class="font-weight-bolder"
                      >{{ process.endDate | moment("DD/MM/Y") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="pb-5">
              <div class="row">
                <div class="col-12">
                  <h3 class="text-primary text-hover-primary font-size-h3 font-weight-bolder mb-3">

                      {{ process.localizations[0].title | truncate(40, '...') }}
                  </h3>
                  <div class="font-weight-normal font-size-h6 max-h100px" maxlength="100">{{ process.localizations[0].description  | truncate(150, '...')}}</div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                  <div class="d-flex flex-row btn btn-md btn-primary  font-weight-bolder text-uppercase rounded align-items-center">
                    <span class="d-none d-sm-block">{{$t("PARTIALSPROCESSI.PARTECIPA")}}</span> <i class="fas fa-angle-double-right pb-1 pr-0  ml-1"></i>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
     </a>
  </div> -->
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";

import ApiService from "@/core/services/api.service";
import {processMixin} from "@/mixins/process";

export default {
  name: "ProcessCardPreview",
  mixins: [processMixin],
  props: ["process", "index"],
  data() {
    return {
      d0: +moment(new Date()).format("YYYYMMDD"),
      d1: +moment(this.process.startDate).format("YYYYMMDD"),
      d2: +moment(this.process.endDate).format("YYYYMMDD"),
      phases: [],
      activePhase: null,
      isManyPhases: null,
    };
  },
  filters: {
    fvisibility(value) {
      return value ? "Pubblico" : "Privato";
    },
    truncate(text, length, clamp){
      clamp = clamp || '...';
      var node = document.createElement('div');
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    },
  },
  computed: {
    // percentage: function () {
    //   if (this.d0 > this.d2) {
    //     return 100;
    //   }
    //   if (this.d0 < this.d1) {
    //     return 0;
    //   }
    //   return Math.round(((this.d0 - this.d1) * 100) / (this.d2 - this.d1));
    // },
    processStatus: function() {
      if (typeof this.d2 === 'number' && this.d0 > this.d2) {
        // return "Concluso";
        return {
          "label":  this.$t("PARTIALSPROCESSI.CONCLUSO"),
          "color": "btn-light-danger"
        };
      } else if (typeof this.d1 === 'number' && this.d0 < this.d1) {
        // return "In attivazione";
        return {
          "label": this.$t("PARTIALSPROCESSI.INATTIVAZIONE"),
            "color": "btn-light-warning"
        };
      } else {
        // return "In corso";
        return {
          "label": this.$t("PARTIALSPROCESSI.INCORSO"),
            "color": "btn-light-primary"
        };
      }
    }
  },
  async beforeMount() {
    // console.log('processo:', this.process );
    this.phases = await this.getProcessPhases();
    this.isManyPhases = this.phases.length > 1;
    this.activePhase = this.phases.find( item => {
      // return (item.phaseStatus.id == 2 && item.open);
      return (item.featured == true);
    });
    console.log("Fase attiva: ", this.activePhase);
  },
  methods: {
    alertInfo(){
      Swal.fire({
        title:  this.$t("PARTIALSPROCESSI.ALERT3TITLE"),
        html: this.$t("PARTIALSPROCESSI.ALERT3"),
        icon: "danger",
        confirmButtonClass: "btn btn-danger"
      });
    },
    getProcessPhases() {
      return new Promise((resolve, reject) => {
        let qs = "?processId.equals=" + this.process.id;

        var endpoint = this.generateUrl('process-phases', true);
        ApiService.get( this.$apiResource, endpoint + qs)
          .then(res => {
            resolve(res.data);
          })
          .catch(({ error }) => {
            console.log("Not good man :((" + error.data.error);
            reject(0);
          });
      });
    },
    alertNotActive(e){
      e.preventDefault();
      Swal.fire({
        title: this.$t("PARTIALSPROCESSI.ALERT2TITLE"),
        text: this.$t("PARTIALSPROCESSI.ALERT2"),
        icon: "warning",
        confirmButtonClass: "btn btn-secondary"
      });
    }

  }
};
</script>

<style scoped></style>

