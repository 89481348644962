<template>
  <div class="row h-100  justify-content-center">
    <div class="col-lg-10 col-xl-10 justify-content-start">
      <div class="d-flex flex-column px-7 mt-10">
        <div class="row align-items-center">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column">
              <h2 class="card-label h1 font-weight-bolder"> {{$t("PROCESSLIST.TITOLO")}}</h2>
            </div>
          </div>
        </div>

        <!--:: Search-->
        <div class="row justify-content-between">
          <div class="col-xl-12">
            <form
              method="get"
              class="quick-search-form"
              style="margin-top:0em; margin-bottom:1em"
            >
              <div class="input-group bg-tranparent border-0">
                <div class="input-group-prepend bg-transparent">
                  <button
                    class="input-group-text line-height-0 py-0 bg-transparent border-0"
                    v-on:click="getSearchData($event)"
                  >
                    <img
                      src="/assets/media/bipart/ico-search.png"
                      alt=""
                      class="w-100"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control py-10 h4 bg-transparent border-0 text-dark-50"
                  placeholder="Search..."
                  v-model="keywords"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
                  >
                    {{ processCount }} {{$t("PROCESSLIST.PROCESSI")}}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- <div class="card card-custom mt-3">
          <b-nav>
            <b-nav-item active>
              <b-dropdown id="dropdown-order" text="ordina per" variant="transparent">
                <b-dropdown-item class="min-w-200px" >
                  <a href="#" v-on:click="orderBy($event, 'id', 'desc')"
                    >Ultimi processi</a
                  >
                </b-dropdown-item>
                <b-dropdown-item class="min-w-200px" >
                  <a href="#" v-on:click="orderBy($event, 'id', 'asc')"
                    >Processi più vecchi</a
                  >
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item class="min-w-200px" >
                  <a href="#" v-on:click="orderBy($event, 'countUsers', 'desc')"
                    >Processi più attivi</a
                  >
                </b-dropdown-item>
                  <b-dropdown-item class="min-w-200px" >
                  <a href="#" v-on:click="orderBy($event, 'countUsers', 'asc')"
                    >Processi meno attivi</a
                  >
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item class="min-w-200px" >
                  <a href="#" v-on:click="orderBy($event, 'budgetMin', 'desc')"
                    >Budget più alto</a
                  >
                </b-dropdown-item>
                <b-dropdown-item class="min-w-200px" >
                  <a href="#" v-on:click="orderBy($event, 'budgetMin', 'asc')"
                    >Budget più basso</a
                  >
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>

            <b-nav-item>
               <b-dropdown id="dropdown-order" text="filtra per tipo" variant="transparent" >
                <b-dropdown-item class="min-w-200px">
                  <a href="#" v-on:click="filterByType($event, 0)">Nessuno</a>
                </b-dropdown-item>
                <b-dropdown-item v-for="item in this.processTypes" :key="item.id" class="min-w-200px">
                  <a href="#" v-on:click="filterByType($event, item.id)">
                    {{item.name}}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>
            <b-nav-item>
              <b-dropdown id="dropdown-order" text="filtra per gruppo" variant="transparent">
                <b-dropdown-item class="min-w-200px">
                  <a href="#" v-on:click="filterByGroup($event, 0)">Rimuovi filtro</a>
                </b-dropdown-item>
                <b-dropdown-item
                  class="min-w-200px"
                  v-for="item in this.processGroups"
                  :key="item.id"
                >
                  <a href="#" v-on:click="filterByGroup($event, item.id)">{{
                    item.name
                  }}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>
            <b-nav-item>
               <b-dropdown id="dropdown-order" text="filtra per istituzione" variant="transparent">
                <b-dropdown-item  class="min-w-200px">
                  <a href="#" v-on:click="filterByInstitution($event, 0)">Rimuovi filtro</a>
                </b-dropdown-item>
                <b-dropdown-item
                  class="min-w-200px"
                  v-for="item in this.processInstitutions"
                  :key="item.id"
                >
                  <a href="#" v-on:click="filterByInstitution($event, item.id)">{{
                    item.name
                  }}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>
          </b-nav>
        </div> -->

        <!--begin::Elenco processi row-1-->
     
        <div v-if="processes" class="w-100 align-items-center">
          <div
            v-for="(item, index) in processes"
            :key="item.id"
          >
            <ProcessCardPreview
              :process="item"
              :index="index"
            ></ProcessCardPreview>
          </div>
        </div>

        <!--begin::Pagination -->
        <Pagination
          v-if="pageCount > 1"
          :current-page="currentPage"
          :page-count="pageCount"
          :item-count="processCount"
          :per-page="$options.static.visibleItemsPerPageCount"
          :visiblePagesCount="this.processCount <= 24 ? 3 : 5"
          class="process-list-pagination gutter-b"
          @firstPage="pageChangeHandle('first')"
          @nextPage="pageChangeHandle('next')"
          @previousPage="pageChangeHandle('previous')"
          @lastPage="pageChangeHandle('last')"
          @loadPage="pageChangeHandle"
        ></Pagination>
        <!--end::Pagination -->



      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

import Pagination from "@/view/layout/common/Pagination";
import ProcessCardPreview from "./partials/ProcessCardPreview";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";
//import Preloader from "@/view/content/Preloader";

export default {
  name: "processList",
  components: {
    Pagination,
    ProcessCardPreview,
    //OrganizationMenu
    //Preloader
  },
  static: {
    visibleItemsPerPageCount: 8
  },
  data() {
    return {
      processes: [],
      processCount: null,
      currentPage: 1,
      pageCount: 0,
      keywords: "",
      orderField: "startDate",
      orderVersus: "desc",

      processTypes: [],
      processGroups: [],
      processInstitutions: [],

      filterTypeId: 0,
      filterGroupId: 0,
      filterInstitutionId: 0,

      orders: [
        {
          label: this.$t("PROCESSLIST.DATAINS"),
          field: "id"
        },
        {
          label: this.$t("PROCESSLIST.DATAINIZIO"),
          field: "startDate"
        },
        {
          label: this.$t("PROCESSLIST.PARTECIPANTI"),
          field: "countUsers"
        },
        {
          label: this.$t("PROCESSLIST.BUDGET"),
          field: "budgetMin"
        }
      ],
      preloaderActive: false
    };
  },
  computed: {},
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("BCPROCESSI.ELENCOPROCESSI") }]);

    this.getProcessTypes();
    this.getProcessGroups();
    this.getProcessInstitutions();
    this.getData();
  },
  created() {},
  methods: {
    getData() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        this.preloaderActive = true;
        let qs =
          "size=" +
          this.$options.static.visibleItemsPerPageCount +
          "&page=" +
          this.currentPage;
        if (this.filterTypeId > 0)
          qs += "&processTypeId.equals=" + this.filterTypeId;
        if (this.filterGroupId > 0)
          qs += "&groupId.equals=" + this.filterGroupId;
        if (this.filterInstitutionId > 0)
          qs += "&institutionId.equals=" + this.filterInstitutionId;

        qs += "&sort=" + this.orderField + "," + this.orderVersus;

        if (this.keywords) qs += "&title.contains=" + this.keywords;

        var endpoint = this.generateUrl("processes-page", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint + "?" + qs)
          .then(res => {
            console.log("Elenco processi: ", res);
            this.processes = res.data.content;
            this.processCount = res.data.meta.total;
            this.pageCount = Math.ceil(
              res.data.meta.total /
                this.$options.static.visibleItemsPerPageCount
            );

            // n. processi con fasi attive (e quindi visibili in process-list)
            let countProcessWithActivePhases = res.data.content.filter(process => process.countProcessPhases > 0 && process.publiclyVisible != false).length;
            if(countProcessWithActivePhases == 1) { // l'organizzazione ha un solo processo?

              let resDataContent = res.data.content;
              let indexProcessWithActivePhases = resDataContent.findIndex(process => process.countProcessPhases > 0 && process.publiclyVisible != false);
              console.log('prova', resDataContent[indexProcessWithActivePhases])

              resDataContent[indexProcessWithActivePhases].countProcessPhases == 1 ? // il processo ha una sola fase?
                  resDataContent[indexProcessWithActivePhases].countProcess2Modules == 1 ? // la fase ha un solo modulo?
                      this.$router.push({ name: "processModuleIdeaList", query: { forced_process: true }, params: { id: resDataContent[indexProcessWithActivePhases].singleProcess2ModuleId, process_id: resDataContent[indexProcessWithActivePhases].id, phase_id: resDataContent[indexProcessWithActivePhases].singleProcessPhaseId }}) : // n. moduli == 1 : vai a idea-list
                      this.$router.push({ name: "processPhase", params: { phase_id: resDataContent[indexProcessWithActivePhases].singleProcessPhaseId, process_id: resDataContent[indexProcessWithActivePhases].id }}) : // n. fasi == 1 : vai a process-phase
                  resDataContent[indexProcessWithActivePhases].featuredProcessPhaseId ? // n. processi == 1, n. fasi > 1,  esiste una fase in primo piano?
                      resDataContent[indexProcessWithActivePhases].featuredProcess2ModuleId ? // la fase in primo piano ha un solo modulo ?
                      this.$router.push({ name: "processModuleIdeaList", query: { forced_process: true }, params: { id: resDataContent[indexProcessWithActivePhases].featuredProcess2ModuleId, process_id: resDataContent[indexProcessWithActivePhases].id, phase_id: resDataContent[indexProcessWithActivePhases].featuredProcessPhaseId }}) :  // fase in primo piano, moduli == 1 -> idea-list
                      this.$router.push({ name: "processPhase", params: { phase_id: resDataContent[indexProcessWithActivePhases].featuredProcessPhaseId, process_id : resDataContent[indexProcessWithActivePhases].id }}) : // n. processi == 1, n. fasi in primo piano == 1
                      this.$router.push({ name: "processList" }); // n. processi == 1, fasi in primo piano == 0 || fasi in primo piano > 1

              /* Le condizioni
              * org.process == 1 ? V
              *   phase == 1 ? V
              *     module == 1 ? idea-list // in questo caso il module:id mi arriva perché phase == 1 e module == 1 V
              *     module > 1 ? process-phase V
              *   phase > 1 ?
              *     phase:featured == 1 V
              *       phase:featured.module == 1 ? idea-list V
              *       phase:feature.module > 1 ? process-phase:featured V
              *     phase:featured == 0 ? process-list V
              *     phase:featured > 1 ? process-list V
              * */

              // da chiedere a Carmelo:
                //  1. id del modulo della phase:featured quando questa ha un solo modulo
                //  2. conferma: featuredProcessPhaseId è diverso da null solo quando esiste una sola fase featured, se più fasi featured o no fasi featured -> null?
            }

            this.$isLoading(false);
            // this.pageCount = res.data.meta.pages;
          })
          .catch(() => {
            // .catch(({ error }) => {
            //   console.log("Not good man :((" + error.data.error);
            // context.commit(SET_ERROR, [response.data.error]);
            this.$isLoading(false);
            reject(0);
          });
      });
    },
    getSearchData(event) {
      event.preventDefault();
      this.getData();
    },
    async pageChangeHandle(value) {
      switch (value) {
        case "next":
          this.currentPage += 1;
          break;
        case "previous":
          this.currentPage -= 1;
          break;
        case "first":
          this.currentPage = 1;
          break;
        case "last":
          this.currentPage = this.pageCount;
          break;
        default:
          this.currentPage = value;
      }

      await this.getData();
    },

    orderBy(event, field = "id", versus = "desc") {
      event.preventDefault();
      this.orderField = field;
      this.orderVersus = versus;
      this.getData();
    },

    getProcessTypes() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("process-types", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            console.log("processTypes: ", res.data);
            this.processTypes = res.data;
            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
      });
    },
    getProcessGroups() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("groups", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            console.log("processGroups: ", res.data);
            this.processGroups = res.data;
            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
      });
    },
    getProcessInstitutions() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("institutions", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            console.log("processInstitutions: ", res.data);
            this.processInstitutions = res.data;
            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    filterByType(event, typeId = 0) {
      event.preventDefault();
      this.filterTypeId = typeId;
      this.getData();
    },
    filterByGroup(event, groupId = 0) {
      event.preventDefault();
      this.filterGroupId = groupId;
      this.getData();
    },
    filterByInstitution(event, institutionId = 0) {
      event.preventDefault();
      this.filterInstitutionId = institutionId;
      this.getData();
    }
  }
};
</script>

<style></style>
